import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import PrimaryButton from "../Button/PrimaryButton"
import PriceTip from "../Button/PriceTip"
import BestPriceTip from "../../images/best.png"
import AffordablePriceTip from "../../images/affordable.png"
import BudgetPriceTip from "../../images/budget.png"

import DotIcon from "../Icons/DotIcon"
import QuotePopup from "../QuotePopup"

import GetQuoteIcon from "../Icons/GetQuoteIcon"
import ItemHover from "../ItemHover"
import { getProductUrl } from "../../utils/product"
// import PhoneIcon from "../Icons/PhoneIcon"
// import NoteIcon from "../Icons/NoteIcon"

const CardWrap = styled.div`
  position: relative;
  width: 95%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.16);
  background: white;
  padding-top: 1px;
  .product-link{display:block;}
  & :hover {
    & img {
      transform: scale(1.1);
      transition: transform ease 0.5s !important;
    }
    & .gatsby-image-wrapper {
      & picture {
        & img {
          transform: scale(1.1);
          transition-delay: none !important;
          transition: transform ease 0.5s !important;
        }
      }
    }
  }
`
const Thumbnail = styled.div`
  position: relative;
  z-index: 30;
  width: 105%;
  max-height: 365px;
  margin-left: -2.5%;
  border-radius: 10px;
  overflow: hidden;
  img {
    border-radius: 10px;
    max-height: 365px;
  }
  & .gatsby-image-wrapper {
    border-radius: 10px;
    max-height: 365px;
  }
`
const CardLabel = styled.div`
  margin: 20px 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #676b79;
  & span {
    margin-left: 10px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
`
const ProductId = styled.div`
  position: relative;
  z-index: 30;
  margin-top: -40px;
  margin-left: -2.5%;
  padding-left: 40px;
  color: white;
  width: 105%;
  text-transform: uppercase;
  font-size: 14px;
  height: 40px;
  font-weight: bold;
  background: linear-gradient(to bottom, rgba(1, 9, 32, 0.01), #010920);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`
const ProductDtl = styled.div`
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  margin:0 -10px;
  padding: 30px;
`
const ProductInfo = styled.div`
  position:relative;
  width:100%;
  padding:0 10px;
  & span {
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    color: #676b79;
    line-height: 26px;
  }
  & p {
    font-family: Roboto;
    text-align: left;
    font-size: 12px;
    line-height: 20px;
    color: #676b79;
    margin-top: 6px;
  }

  @media (min-width: 1200px) {
    flex-basis: 0;  
    flex-grow: 1;
    max-width: 100%;
  }

`

const ProductToolbar = styled.div`
  position:relative;
  width:100%;
  padding:0 10px;
  @media (min-width: 1200px) {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
`
const ProductService = styled.div`  
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
  color: #676b79;
  margin-bottom:5px;
`
const ProductPriceDesc = styled.div`  
  font-size: 12px;
  line-height: 20px;
  color: #676b79;
  text-align: left;
  margin-bottom:10px;
`


// const CardBottom = styled.div`
//   bottom: 0;
//   position: absolute;
//   border-top: 1px solid #e4e0e0;
//   width: 100%;
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   background: rgba(1, 9, 32, 0.02);
//   border-radius: 0px 0px 10px 10px;
//   text-align: center;
//   font-size: 16px;
//   line-height: 20px;
//   @media (min-width: 567px){
//     font-size: 20px;
//     line-height: 24px;
//   }
//   & a {
//     text-decoration: none;
//   }
// `
// const CallButton = styled.div`
//   padding: 17px 0;
//   border-right: 1px solid #e4e0e0;
//   color: #ed602b;
//   cursor: pointer;
//   transition: color 0.3s linear;
//   & span {
//     vertical-align: middle;
//     font-family: Chakra Petch;
//     font-weight: 700;
//     margin-left: 5px;
//     @media (min-width: 567px) {
//       margin-left: 10px;
//     }
//   }
//   & svg {
//     vertical-align: middle;
//     & path {
//       transition: fill 0.3s linear;
//     }
//   }
//   & :hover {
//     color: #1e4156;
//   }
// `
const ViewDetail = styled.div`
  color: #1e4156;
  padding: 17px 0;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s linear;
  & span {
    vertical-align: middle;
    margin-left: 10px;
    font-family: Chakra Petch;
    font-weight: 700;
  }
  & svg {
    vertical-align: middle;
    & path {
      transition: fill 0.3s linear;
    }
  }
  & :hover {
    color: #ed602b;
  }
`
const ProductName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #062c44;
  line-height: 24px;
  margin-bottom: 6px;
  font-family: Chakra Petch, sans-serif;
  text-transform: capitalize;
  > a{
    color: #062c44;
    &:hover, &:focus{
      color:#ed602b;
    }
  }
`
// const Price = styled.p`
//   font-size: 34px !important;
//   font-weight: 700 !important;
//   line-height: 41px !important;
//   float: right;
//   @media (max-width: 768px) {
//     font-size: 30px !important;
//     float: left;
//   }
// `
class ProductCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isQuoteVisible: false
    }
    this.priceTip = this.priceTip.bind(this)
    this.availableService = this.availableService.bind(this)
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalOpen() {
    this.setState({
      isQuoteVisible: true,
    })
    document.querySelector("body").classList.add("modal-open")
  }
  handleModalClose() {
    this.setState({
      isQuoteVisible: false,
    })
    document.querySelector("body").classList.remove("modal-open")
  }
  availableService(data) {
    let services = []
    if (data.node.productServices) {
      data.node.productServices.forEach(function (item) {
        if (
          item.service === "Financing Available" ||
          item.service === "Rent To Own Available"
        ) {
          services.push(item)
        }
      })
    }
    if (services.length === 0) {
      return null
    } else if (services.length === 1) {
      return <span>{services[0].service}</span>
    } else {
      return (
        <div>
          <span>{services[0].service}</span>
          <span>
            {" "}
            <DotIcon /> {services[1].service}
          </span>
        </div>
      )
    }
  }
  priceTip(data) {
    if (data.node.productRoofType.name === "Vertical Roof") {
      return (
        <div>
          <PriceTip text="Best" bgImg={BestPriceTip} width="75px" /> Vertical
          Roof - Highly Recommended
        </div>
      )
    } else if (data.node.productRoofType.name === "A-Frame Roof") {
      return (
        <div>
          <PriceTip
            text="Affordable"
            bgImg={AffordablePriceTip}
            width="110px"
            pr="7px"
          />{" "}
          A-Frame Roof - Better Quality
        </div>
      )
    } else {
      return (
        <div>
          <PriceTip
            text="Budget"
            bgImg={BudgetPriceTip}
            width="90px"
            pr="5px"
          />{" "}
          Regular Roof - Good Quality
        </div>
      )
    }
  }
  render() {
    const { data, location } = this.props
    const productUrl = getProductUrl(data.node);

    return (
      <>
        <CardWrap className="card-wrap">
          <CardLabel>{this.priceTip(data)}</CardLabel>
          {(data.node.image?.fluid || data.node.imageSrc) && (
            <Link to={productUrl} className="product-link">
              <Thumbnail>
                {data.node.image ? (
                  <Img fluid={data.node.image.fluid} alt={data.node.title} />
                ) : (
                  <img src={data.node.imageSrc} alt={data.node.title} />
                )}
              </Thumbnail>
            </Link>
          )}
          <ProductId>PB#{data.node.skuNumber}</ProductId>
          <ProductDtl className="product-dtl">
            <ProductInfo className="product-info">
              <ProductName className="card-title"><Link to={productUrl}>{data.node.title}</Link></ProductName>
              <ProductService>{this.availableService(data)}</ProductService>
              <ProductPriceDesc>{data.node.priceDescription}</ProductPriceDesc>
            </ProductInfo>
            <ProductToolbar className="product-toolbar"><Link to={productUrl}><PrimaryButton text="View Details" /></Link></ProductToolbar>
            {/* <div>
            <Price>${data.node.price}*</Price>
          </div> */}
          </ProductDtl>
          {/* <CardBottom>
          <ItemHover>
            {hoverItem => (
              <a href="tel:8777541818">
                <CallButton>
                  <PhoneIcon fill={hoverItem ? "#1E4156" : "#ED602B"} />
                  <span>Call For Pricing</span>
                </CallButton>
              </a>
            )}
          </ItemHover>
          <button type="button"  onClick={this.handleModalOpen}>
            <ItemHover>
              {hoverItem => (
                <ViewDetail>
                  <GetQuoteIcon fill={hoverItem ? "#ED602B" : "#1E4156"} />
                  <span>Get A Quote</span>
                </ViewDetail>
                
              )}
            </ItemHover>
          </button>
        </CardBottom>*/}
        </CardWrap>
        <QuotePopup isVisible={this.state.isQuoteVisible} onClose={this.handleModalClose} location={location} formName="Product Get a Quote Form" />
      </>
    )
  }
}

export default ProductCard
